import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import current from "../images/archipelago/current.webp";
import future from "../images/archipelago/future.webp";
import platform from "../images/archipelago/platform.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Archipelago" keywords={['Seraph', 'kumar', 'designer', 'user', 'experience', 'product', 'interaction', 'b2b', 'saas', 'web', 'app', 'platform', 'gatsby', 'application', 'react']} mdxType="SEO" />
    <img height="auto" loading="eager" src={platform} alt="Mockup of the Archipelago platform." className="center" /><br />
    <img height="auto" loading="eager" src={current} alt="Mockup of iteration 1 of the file explorer." className="center" /><br />
    <img height="auto" loading="eager" src={future} alt="Mockup of iteration 2 of the file explorer." className="center" /><br /><br />
    <h1>{`Designing A File Explorer`}</h1>
    <div className="nda"> Content is limited and altered to abide by NDAs </div>
    <h2>{`Archipelago`}</h2>
    <p>{`Archipelago Analytics handles property insurance data for companies like Amazon and BlackRock.
Their online platform handles massive data sets.`}</p>
    <h2>{`Navigating Data`}</h2>
    <p>{`Since clients were submitting hundreds of files for their properties every week, it made sense we would
launch a file storage solution. In a future iteration, this feature would let you pinpoint the exact files
containing the data you're looking for. But for the first iteration, it would just need to work.`}</p>
    <p>{`I didn't want this to be just another file exploration. So I designed a unique value proposition: filtering
and organizing capabilities that would let you find what you need faster. Ideally our search function should
work like magic, but we weren't at a point where we could offer that yet.`}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      